@import '/src/styles/var';
@import '/src/styles/mixins';

.text {
  padding-top: toRem(96);
  padding-bottom: toRem(80);

  .textTitle {
    margin-bottom: toRem(8);
  }
  .textContent {
    padding-top: toRem(40);
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: toRem(20);
    }
    a {
      color: $green700;
      text-decoration: underline;
    }
  }
}