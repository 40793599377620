@import '/src/styles/var';
@import '/src/styles/mixins';

.editorialSplit {
  padding-top: toRem(48);
  padding-bottom: toRem(48);
  position: relative;

  .contactFormAnchor {
    position: absolute;
    top: - toRem(160);
  }

  .separator {
    width: toRem(80);
    height: toRem(8);
    background: linear-gradient(90deg, #1E3591 28.65%, #72C242 100%);
    margin-bottom: toRem(12);
    border-radius: 0 4px 0 0;
  }

  .textContent {

    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    p {
      margin-bottom: toRem(20);
    }

    a {
      color: $green700;
      text-decoration: underline;
    }

    >*:last-child {
      margin-bottom: 0;
    }
  }
}
