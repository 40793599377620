@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormSummary {
  margin: 0 toRem(32);
  position: relative;

  &.isModal {
    margin: 0 toRem(-32);

    @include media-breakpoint-down(lg) {
      margin: 0 toRem(-8);
    }

    .borderedWrapper {
      border-right: 0;

      @include media-breakpoint-down(lg) {
        margin: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin: 0;
  }

  .borderedWrapper {
    border: toRem(1) solid $mirage950;
    border-left: toRem(16) solid $mirage950;

    @include media-breakpoint-down(lg) {
      margin: 0 toRem(8);
    }

    :global(.MuiTypography-root) {
      color: $mirage950 !important;
    }

    .numberBox {
      display: flex;
      flex-direction: column;
      padding: toRem(24) toRem(16);
      padding-right: toRem(64);
      height: 100%;

      @include media-breakpoint-down(lg) {
        padding: toRem(12) toRem(8);
        padding-right: 25%;
      }

      .largeNumber {
        width: 100%;
        font-size: toRem(96);
        font-weight: bold;
        line-height: 1;
        margin-bottom: toRem(16);
        border-bottom: toRem(1) solid $mirage950;

        @include media-breakpoint-down(lg) {
          font-size: toRem(80);
        }

        :global(.MuiTypography-root) {
          float: left;
          font-size: 6rem;

          @include media-breakpoint-down(lg) {
            font-size: 5rem;
          }
        }
      }

      .smallText {
        font-size: toRem(20);
      }
    }

    .headerBox {
      background-color: $grey10;
      color: $mirage950;
      padding: toRem(16);
      border-bottom: toRem(1) solid $mirage950;

      @include media-breakpoint-down(lg) {
        padding: toRem(8);
      }
    }

    .descriptionBox {
      padding: toRem(16);
      margin-bottom: toRem(16);

      :global(MuiTypography-root) {
        font-size: toRem(22);
        padding-left: toRem(16);
        float: left;
      }

      > svg {
        width: toRem(72);
        float: left;
        margin-right: toRem(16);

        @include media-breakpoint-down(lg) {
          float: none;
          margin-bottom: toRem(-8);
        }
      }
    }
  }
}
