@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormModal {
  padding: toRem(16) toRem(32);
  position: relative;
  // background-color: $emerald500;
  background-color: $mirage200;
  padding-top: toRem(16);
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-top: 1px solid $mirage950;
  margin-top: toRem(16);

  :global(.MuiTypography-root) {
    color: $mirage950 !important;
  }

  @include media-breakpoint-down(lg) {
    padding: toRem(8);
    padding-top: toRem(8);
  }

  + .longFormModal {
    border-top: none;
    margin-top: 0;
  }
}

.container {
  background-color: $athensGray100;
  border: 1px solid $mirage950;
  width: 83.33%;
  cursor: pointer;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.header {
  align-items: center;
  display: flex;
  border-bottom: 1px solid $mirage950;
  overflow: hidden;
  justify-content: space-between;
}

.headerTitle {
  width: 100%;
  color: $mirage950;
  display: flex;
  border: 1px solid $mirage950;
  border-top: 0;
  border-bottom: 0;
  align-items: center;
  padding: toRem(12) toRem(32);
  height: toRem(84);

  @extend %hide-scrollbar;

  @include media-breakpoint-down(lg) {
    height: toRem(56);
    width: calc(100% - toRem(56));
    overflow-x: auto;
    padding: toRem(6) toRem(0);
    border: 0;
    border-left: 1px solid $mirage950;
  }
}

.headerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(lg) {
    min-width: toRem(56);
    height: toRem(56);
  }

  > img {
    height: toRem(40);
    width: auto;

    @include media-breakpoint-down(lg) {
      height: toRem(24);
    }
  }
}

.versionBox {
  background-color: $emerald500;
  height: toRem(84);
  align-items: center;
  justify-content: center;
  display: flex;

  @include media-breakpoint-down(lg) {
    // display: none;
    background-color: $emerald500;
    width: toRem(64);
    height: toRem(64);
    border-radius: 50%;
    margin-bottom: toRem(8);
    margin-top: toRem(48);
    :global(.MuiTypography-root) {
      font-size: toRem(40) !important;
    }
  }
}

.stripedSection {
  width: 100%;
  display: block;
  height: toRem(96);
  background-image: url("/assets/images/45_lines.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid $mirage950;
  border-top: 0;
  border-bottom: 0;

  @include media-breakpoint-down(lg) {
    background-image: url("/assets/images/45_lines_mobile.svg");
    height: toRem(48);
    border: 0;
  }
}

.content {
  padding: toRem(38) toRem(32) toRem(16);
  background-color: $athensGray50;
  border: 1px solid $mirage950;
  border-top: 0;
  border-bottom: 0;
  min-height: 100%;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }

  .modal & {
    padding-bottom: toRem(0);

    @include media-breakpoint-down(lg) {
      padding: 0 toRem(8) toRem(0);
    }
  }

  @include media-breakpoint-down(lg) {
    border: 0;
  }
}

.modalTitle {
  margin-bottom: toRem(16);
  
  > :global(.MuiBox-root) {
    line-height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @extend %hide-scrollbar;
  }
}

.subtitle {
  font-size: 1rem;
  color: #6c757d;
  max-width: 80%;
}

.line {
  width: calc(100% + toRem(32));
  height: toRem(16);
  background-color: $mirage950;
  margin-top: toRem(16);
}

.lg1 {
  width: 8.33%;

  @include media-breakpoint-down(lg) {
    width: 0;
  }

  &.headerIcon {
    @include media-breakpoint-down(lg) {
      width: toRem(56);
    }
  }
}

.lg10 {
  width: 83.33%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.row {
  display: flex;

  &.rowContent {
    .modal & {
      height: calc(100% - toRem(182));

      @include media-breakpoint-down(lg) {
        height: calc(100% - toRem(105));
      }
    }
  }
}

.modal {
  
  .container {
    cursor: default;

  }

  :global(.MuiTypography-root) {
    color: $mirage950 !important;
  }

  .longFormModalContent {
    position: relative;
    z-index: 9999;
    background-color: $white;
    background-color: $mirage200;
    display: flex;
    justify-content: center;
    padding: toRem(57);
    height: 100%;
    flex-wrap: wrap;
    overflow: auto;

    @include media-breakpoint-down(lg) {
      padding: toRem(8);
    }
  }

  .closeButton {
    position: fixed;
    top: toRem(70);
    right: toRem(60);
    cursor: pointer;
    background-color: $emerald500;
    color: $mirage950;
    z-index: 1000;
    width: toRem(56);
    height: toRem(56);

    @include media-breakpoint-down(lg) {
      top: toRem(8);
      right: toRem(8);
      zoom: 0.75;
    }
  }
}
