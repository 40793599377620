@import '/src/styles/var';
@import '/src/styles/mixins';

.obiettiviWrapper {
  position: relative;
  padding-top: toRem(40);
  padding-bottom: toRem(64);

  @include media-breakpoint-up(lg) {
    padding-top: toRem(74);
    padding-bottom: toRem(80);
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .itemIcon {
      position: relative;
      margin-bottom: toRem(24);
      width: toRem(104);
      height: toRem(104);

      svg {width: 100%; height: auto;}

      .itemNumber{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
      }
    }

    .itemImage {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .itemTitle {
      margin-bottom: toRem(16);
      
      color: $grey100;
    }
    .itemDesc {
      margin-bottom: toRem(32);
      color: $grey100;
    }
    .itemCta {
      margin-top: auto;
    }
  }

  .copyright{
    position: absolute;
    left: 0;
    text-align: right;
    padding: toRem(16);
    font-size: toRem(16);
    font-weight: normal;
    line-height: toRem(20);
    z-index: 999;
    opacity: 0;
    animation: fadeIn 1.6s ease-in .2s forwards;
    width: 100%;

    @include media-breakpoint-up(lg) {
      opacity: 1;
      animation: none;
      bottom: 0;
      padding-right: 3vh;

    }
    @include media-breakpoint-down(lg) {
      opacity: 1;
      animation: none;
      font-size: toRem(12);
      line-height: toRem(16);
      opacity: 1;
      animation: none;
      bottom: 0;
      padding-right: 2vh;

    }
  }

  &.background {
    background-size: cover;
    background-position: center;
    padding-top: toRem(120);
    padding-bottom: toRem(120);

    @include media-breakpoint-up(lg) {
      padding-top: toRem(170);
      padding-bottom: toRem(170);
    }
    :global(.darkMode) & {
      filter: invert(1);
    }
  }

  &.themeLight {
    color: $white;
    .title { color: $white; }
    .item {
      .itemIcon {
        .itemNumber {color: $white;}
      }
      .itemTitle {color: $white;}
      .itemDesc {color: $white;}
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, $blue300 0%, $blue900 100%);
      :global(.darkMode) & {
        
      }

      &.overlayBg {
        background: $gradientBlue;
        opacity: .7;
        mix-blend-mode: multiply;
        :global(.darkMode) & {
          filter: invert(1);
        }
      }
    }

    :global(.MuiGrid-root) {
      z-index: 2;
    }

    .copyright {
      color: $white;
      background: linear-gradient(0deg, rgba(0, 26, 112, 0.5) 0%, transparent 100%);
    }
  }

  &.themeDark {
    color: initial;
  }
}