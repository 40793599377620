@import '/src/styles/var';
@import '/src/styles/mixins';

.NewsContent {
  position: relative;
  z-index: 100;


  .item {
    .imgTitle {
      font-weight: 400;
    }

    .imgNote {
      font-weight: 300;
    }

    .svgImg {
      @include media-breakpoint-down(lg) {
        display: flex;
        width: calc(100vw - toRem(44));
      }

      >svg {
        height: auto;
        width: 100%;
      }
    }

    .separator {
      width: toRem(80);
      height: toRem(8);
      background: linear-gradient(90deg, #1E3591 28.65%, #72C242 100%);
      margin-bottom: toRem(12);
      border-radius: 0 4px 0 0;
    }

    .textContent {

      h2,
      .h2,
      h3,
      .h3,
      h4,
      .h4,
      h5,
      .h5,
      h6,
      .h6,
      p {
        margin-bottom: toRem(20);
      }

      a {
        color: $green700;
        text-decoration: underline;
      }

      >*:last-child {
        margin-bottom: 0;
      }
    }
  }
}

:global(.swiper)+.NewsContent {
  margin-top: -4rem;

  @include media-breakpoint-up(lg) {
    margin-top: 16vh;
  }
}