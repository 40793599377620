@import '/src/styles/var';
@import '/src/styles/mixins';

.tabWrapper {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  min-height: calc(80vh - toRem(72));

  @include media-breakpoint-up(lg) {
    min-height: calc(80vh - toRem(92));
  }

  .tabs {
    background-color: $grey5;
    padding-top: toRem(18);
    position: sticky;
    z-index: 999;
    top: toRem(66);

    @include media-breakpoint-up(lg) {
      top: toRem(70);
    }

    :global(.MuiButtonBase-root) {
      font-size: toRem(18);
      line-height: toRem(28);
      font-weight: 500;
      @include media-breakpoint-down(lg) {
        padding-left: toRem(38);
        padding-right: toRem(38);
      }
    }
  }
}

.tabContent {
  height: 100%;
  overflow: hidden;
  background-color: $white;
  padding-top: toRem(40);
  padding-bottom: toRem(80);
  @include media-breakpoint-up(lg) {
    padding-top: toRem(40);
    padding-bottom: toRem(60);
  }

  .tabContentInner {
    height: 100%;
  }

  .tabColumn {
    position: relative;

    &:not(:last-child) {
      &::before {
        content: "";
        background-color: $grey30;
        position: absolute;
        @include media-breakpoint-down(lg) {
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
        }
        @include media-breakpoint-up(lg) {
          height: 100%;
          width: 1px;
          right: 0;
          top: 0;
        }
      }
    }

    .tabColumnInner {
      // max-width: toRem(560);
      // margin-left: auto;
      // margin-right: auto;
      padding-top: toRem(64);
      padding-bottom: toRem(64);

      @include media-breakpoint-up(lg) {
        padding-top: toRem(16);
        padding-bottom: toRem(16);
      }

      .icon {
        margin-bottom: toRem(8);
        svg {
          width: toRem(72);
          height: auto;
        }
      }
      .title {
        color: $blue900;
        margin-bottom: toRem(36);
      }

      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin-bottom: toRem(20);
      }
      a {
        color: $green700;
        text-decoration: underline;
      }
    }
  }

}
