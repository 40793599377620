@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormInfoNumberedBox {
  margin: -1px toRem(32);
  position: relative;

  @include media-breakpoint-down(lg) {
    margin: 0;
  }

  .gridItem {
    @include media-breakpoint-up(lg) {
      -webkit-flex-basis: 70%;
      -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 70%;
    }
  }

  .numberedBox {
    border: 1px solid $mirage950;

    .headerBox {
      background-color: #1c2331;
      color: white;
      padding: 16px;
      display: flex;
      align-items: center;

      :global(.MuiTypography-root) {
        color: $athensGray50;
        position: relative;
      }

      .titleLine {
        height: toRem(8);
        width: toRem(286);
        background-color: $white;
        margin-bottom: toRem(12);
        transition: all 0.3s;

        @include media-breakpoint-down(lg) {
          width: calc(100% - toRem(27));
        }
      }
    }

    .numberCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $mirage950;
      color: $athensGray50;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      font-weight: bold;
      font-size: 1.5rem;
      border: toRem(1) solid $athensGray50;

      @include media-breakpoint-up(lg) {
        position: relative;
        left: toRem(-40);
        margin: 0;
      }
    }

    .contentBox {
      padding: 24px;
    }
  }
}
