@import "/src/styles/var";
@import "/src/styles/mixins";

.heroSwiper {
  position: relative;
  z-index: 0;
  top: toRem(0);
  animation: fadeIn 1.6s ease-in;
  // height: 100vh;
  height: calc(var(--app-height) - toRem(70));
  transition: height 0.25s;

  @include media-breakpoint-up(md) {
    @media (orientation: portrait) {
      top: toRem(-96);
    }
  }

  @include media-breakpoint-up(lg) {
    top: toRem(-167.5);
    height: 116vh;
    margin-bottom: -24vh;

    :global(.sticky) & {
      top: toRem(-95.5);
    }
  }

  .copyright {
    position: absolute;
    left: 0;
    text-align: right;
    padding: 0 toRem(16);
    color: $white;
    font-size: toRem(16);
    font-weight: normal;
    line-height: toRem(20);
    z-index: 999;
    opacity: 0;
    animation: fadeIn 1.6s ease-in 0.2s forwards;

    @include media-breakpoint-up(lg) {
      opacity: 1;
      animation: none;
      bottom: 18vh;
      right: 0;
      left: unset;
      max-width: 22%;
    }

    @include media-breakpoint-down(lg) {
      opacity: 1;
      animation: none;
      top: 25vh;
      font-size: toRem(12);
      line-height: toRem(16);
      right: 20vw;
    }
  }

  &.blue {
    :global(.MuiTypography-root) {
      color: $white;

      :global(.darkMode) & {
        color: $black;
      }
    }

    .heroWrapper {
      .heroImage {
        @include media-breakpoint-down(lg) {
          height: 116vh;
          width: auto;

          @media (orientation: portrait) {
            left: 50%;
            width: 100%;
            height: auto;
            transform: translateX(-50%);
          }
        }
      }

      :global(.social-share) {
        a {
          color: $white;
        }
      }
    }

    :global(.swiper-pagination) {
      .swiperBullet {
        @include media-breakpoint-down(lg) {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .heroWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 116vh;
    }

    .heroWrapperContent {
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 999;
      display: flex;
      align-items: flex-start;
      height: calc(100vh + toRem(170));

      @include media-breakpoint-down(lg) {
        top: 35%;
        padding-bottom: toRem(72);
        height: auto;
        transition: height 0.25s;

        @media (orientation: landscape) {
          padding-bottom: toRem(64);
        }
      }
      .container {
        @include media-breakpoint-up(lg) {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .row {
          .content {
            .desc {
              margin-top: 1rem;
              margin-bottom: 1.5rem;
              :is(h4, h5) {
                font-family: $headingFontLight;
              }
            }

            .subtitle {
              margin-top: 0.5rem;
              // margin-bottom: 1.5rem;
            }

            @include media-breakpoint-up(sm) {
              .desc, .subtitle {
                margin-top: 1.3rem;
                margin-bottom: 1.8rem;
              }
            }
            @include media-breakpoint-up(md) {
              .desc, .subtitle {
                margin-top: 1.5rem;
                margin-bottom: 2rem;
              }
            }
            @include media-breakpoint-up(lg) {
              max-width: 50%;
            }

            @include media-breakpoint-down(lg) {
              @media (orientation: landscape) {
                max-width: 50%;
                max-height: 60vh;

                .desc, .subtitle {
                  margin-top: toRem(12);
                  margin-bottom: toRem(20);
                }
              }
            }
          }

          .logoImage {
            max-height: toRem(36);
            max-width: 100%;
            height: auto;
            width: auto;
            margin-bottom: toRem(8);

            @include media-breakpoint-down(lg) {
              @media (orientation: landscape) {
                position: absolute;
                right: toRem(22);
                max-height: toRem(46);
              }
            }

            @include media-breakpoint-up(lg) {
              max-height: toRem(72);
            }
          }
        }
      }
    }

    .heroImage {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 116vh;

      @include media-breakpoint-up(lg) {
        object-fit: cover;
        object-position: bottom;
      }

      @include media-breakpoint-down(lg) {
        position: absolute;
        top: 0;
        left: 50%;
        height: auto;

        @media (orientation: landscape) {
          width: 100vw;
        }
      }
    }
  }

  :global(.swiper-slide) {
    transition-property: opacity;

    &:global(.swiper-slide-active) {
      z-index: 4;
    }
  }

  :global(.swiper-pagination) {
    left: inherit;
    width: toRem(4);
    right: 12vw;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;

    @include media-breakpoint-down(lg) {
      width: 80vw;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: row;
      justify-content: center;
      top: auto;
      bottom: toRem(32);
    }

    .swiperBullet {
      background-color: rgba(255, 255, 255, 1);
      height: toRem(48);
      width: toRem(4);
      border-radius: 40px;
      margin-bottom: 0.5rem;
      opacity: 1;

      @include media-breakpoint-down(lg) {
        // rgba(255, 255, 255, 0.2);
        background-color: rgba(0, 0, 0, 0.1);
        width: toRem(40);
        height: toRem(4);
        margin: 0 toRem(8);
      }
    }

    .swiperBulletActive {
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        animation: gradient 6.6s ease infinite;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), $green700);
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(lg) {
          background: $green700;
          bottom: 0;
          left: -100%;
          animation: gradientMob 6.6s ease infinite;
        }
      }
    }
  }
}

@keyframes gradient {
  0% {
    bottom: -100%;

    @include media-breakpoint-down(lg) {
      bottom: 0;
      left: -100%;
    }
  }

  100% {
    bottom: 0;
  }
}

@keyframes gradientMob {
  0% {
    bottom: 0;
    left: -100%;
  }

  100% {
    left: 0;
  }
}