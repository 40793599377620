@import '/src/styles/var';
@import '/src/styles/mixins';

.download,
.callmenow {
  padding-top: toRem(24);
  padding-bottom: toRem(24);
  .downloadItem,
  .callmenowItem {
    padding: toRem(24);
    display: flex;
    align-items: center;
    gap: toRem(24);
    background-color: $grey10;
    color: $grey100;
    text-decoration: none;
    transition: all .2s;

    &:hover { background-color: $grey20; }

    > svg:first-child,
    > span{color: $grey100;}
  }

  .callmenowItem {
    justify-content: space-between;
    .callmenowNumber {
      color: $green700;
    }
  }
}