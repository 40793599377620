@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormIntro {
  display: flex;
  padding: 0 0 0 toRem(32);
  background-color: $athensGray50;

  @include media-breakpoint-down(lg) {
    padding: 0 0 0 toRem(8);
  }

  .longFormIntroLeftSideContainer {
    position: relative;
  }

  .longFormIntroLeftSide {
    margin-top: toRem(16);

    @include media-breakpoint-down(lg) {
      margin-bottom: toRem(40);
    }

    .longFormIntroLeftSideTop {
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .longFormIntroShare {
      margin-top: toRem(40);

      @include media-breakpoint-down(lg) {
        margin-top: toRem(18);
      }
    }

    .longFormIntroShareSocials {
      display: flex;
      margin-top: toRem(24);
      flex-wrap: wrap;
      row-gap: toRem(16);
    }

    .longFormIntroChapterContainer {
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: row;

        > div {
          padding-right: toRem(28);
        }
      }
    }

    .longFormIntroChapter,
    .longFormIntroReadingTime {
      margin-top: toRem(40);

      @include media-breakpoint-down(lg) {
        margin-top: toRem(24);
      }
    }

    .longFormIntroChapterContent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: toRem(56);
      height: toRem(56);
      border-radius: 50%;
      padding: toRem(5) toRem(16.5);
      background-color: $mirage100;
      margin-top: toRem(12);

      > h2 {
        color: $mirage950 !important;
      }
    }

    .longFormIntroTitle {
      margin-top: toRem(20);
      margin-right: toRem(117);
      border-top: 16px solid $mirage950;

      @include media-breakpoint-down(lg) {
        margin-right: 0;
        margin-top: toRem(40);
      }
    }
  }

  .longFormIntroRightSideContainer {
    @include media-breakpoint-down(lg) {
      margin-left: toRem(-16);
      min-width: calc(100% + toRem(32));
    }
  }

  .longFormIntroRightSide {
    background-color: $mirage950;
    min-height: 100%;
    padding: toRem(32) toRem(40);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      padding: toRem(24) toRem(8) toRem(40);
      // width: calc(100% - 0.5rem);
    }

    .longFormIntroRightSideUp {
      display: flex;

      @include media-breakpoint-down(lg) {
        margin-bottom: toRem(16);
      }

      .longFormIntroRightSideUpArrow {
        display: flex;
        align-items: center;
        margin-right: toRem(16);
      }
    }

    .longFormIntroRightSideUpTitle > h3 {
      line-height: 110%;
      hyphens: auto;
      word-wrap: break-word;
    }

    .longFormIntroRightSideUpDesc {
      margin-top: toRem(24);

      @include media-breakpoint-down(lg) {
        margin-top: toRem(16);
      }

      > p {
        text-transform: uppercase;
        color: $athensGray50;
      }
    }

    .longFormIntroRightSideItems {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      .longFormIntroRightSideItem {
        min-height: 100%;
        height: 100%;
        // min-width: 50%;

        @include media-breakpoint-down(lg) {
          margin-top: toRem(24);
          width: 100%;
          margin-left: 0;
        }

        .longFormIntroRightSideItemTitle {
          margin-bottom: toRem(16);
          min-width: 100%;
          display: flex;

          > :global(.MuiTypography-h1) {
            font-size: toRem(110);
            letter-spacing: -2.8px;
            line-height: normal;

            @include media-breakpoint-down(lg) {
              font-size: toRem(100);
            }
          }
        }

        .longFormIntroRightSideItemDesc {
          padding-top: toRem(16);
          border-top: 8px solid $athensGray50;
          > p {
            text-transform: uppercase;
            color: $athensGray50;
          }
        }
      }
    }
  }
}
