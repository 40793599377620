@import "/src/styles/var";
@import "/src/styles/mixins";

.accordion {
  padding-left: toRem(32);
  padding-right: toRem(32);
  color: $mirage950;
  background-color: $athensGray50;

  @include media-breakpoint-down(lg) {
    padding-left: toRem(8);
    padding-right: toRem(8);
  }

  .titleLine {
    height: toRem(8);
    width: toRem(286);
    background-color: $white;
    margin-bottom: toRem(12);
    transition: all 0.3s;

    @include media-breakpoint-down(lg) {
      width: 90%;
    }
  }

  :global(.MuiPaper-root) {
    box-shadow: none !important;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      :global(.MuiCollapse-root) {
        border-bottom: 1px solid $mirage950;
      }
    }

  }

  :global(.MuiAccordionSummary-root) {
    padding: toRem(32) !important;
    @include media-breakpoint-down(lg) {
      padding: toRem(16) toRem(8) !important;
    }
  }

  :global(.MuiAccordionSummary-content) {
    flex-direction: column;
  }

  :global(.MuiSvgIcon-root) {
    width: toRem(40);
    height: toRem(40);

    @include media-breakpoint-down(lg) {
      width: toRem(32);
      height: toRem(32);
    }
  }

  :global(.MuiButtonBase-root) {
    background-color: $mirage950;
    color: $white;
    transition: all 0.3s;
    border: 1px solid $mirage950;
    border-bottom: none;

    &:global(.Mui-expanded) {
      background-color: $white;
      color: $mirage950;
      .titleLine {
        background-color: $mirage950;
      }
    }
    &:global(.MuiTypography-root) {
      background: transparent;
      transition: all 0.3s;
    }
    &:global(.Mui-expanded .MuiTypography-root) {
      color: $mirage950;
    }
  }

  :global(.MuiCollapse-root .MuiCollapse-wrapper) {
    border: 1px solid $mirage950;
  }

  :global(.MuiAccordionDetails-root) {
    padding: toRem(32) !important;

    @include media-breakpoint-down(lg) {
      padding: toRem(16) !important;
    }
  }

  :global(.MuiAccordionDetails-root .MuiTypography-root.MuiTypography-bodyM) {
    color: $mirage950;
    font-size: toRem(16);

    @include media-breakpoint-up(lg) {
      font-size: toRem(20) !important;
    }
  }

  :global(.MuiAccordionDetails-root .MuiTypography-root.MuiTypography-bodyL) {
    color: $white;
    background-color: $mirage950;
    width: fit-content;
    border-radius: 50%;
    width: toRem(48);
    height: toRem(48);
    font-size: toRem(48);
    align-items: center;
    justify-content: center;
    display: flex;

    @include media-breakpoint-down(lg) {
      font-size: toRem(36);
      width: toRem(36);
      height: toRem(36);
      margin-bottom: toRem(16);
    }
  }

  .image {
    max-width: 100%;
    height: auto;
  }

  // .accordionContent {
  //   p {
  //     margin-bottom: toRem(30);
  //   }
  // }
}
