@import '/src/styles/var';
@import '/src/styles/mixins';

.formContainer {
  padding-top: toRem(48);
  padding-bottom: toRem(48);
  position: relative;

  .contactFormAnchor {
    position: absolute;
    top: - toRem(160);
  }
}
