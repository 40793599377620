@import "/src/styles/var";
@import "/src/styles/mixins";

.comunita-energetiche-rinnovabili {
  width: 100%;
  padding-top: toRem(20) !important;
  padding-bottom: 0 !important;

  .pillarItem {
    width: 100%;

    @include media-breakpoint-down(lg) {
      padding-top: toRem(16);
      padding-bottom: toRem(16);
    }

    .imageWrapper {
      z-index: 1;
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center !important;
      align-items: center !important;

      .image {
        height: auto !important;
        min-height: auto !important;
        max-width: 100%;
        :global(.darkMode) & {
          filter: invert(1);
        }
      }
    }

    .content {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 12px;

      h3 {
        margin-bottom: 20px;
      }

      h5,
      p {
        margin-bottom: 15px;
      }

      h5 {
        color: black;
      }

      p {
        font-weight: 500;
        line-height: 1.5;
      }
    }
  }
}

.pillar {
  padding-top: toRem(50);
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding-bottom: toRem(120);
    :global(.page-news) & {
      padding-bottom: toRem(50);
    }
  }

  .pillarItem {
    @include media-breakpoint-down(lg) {
      padding-top: toRem(16);
      padding-bottom: toRem(16);
    }
    .imageWrapper {
      z-index: 1;
      position: relative;
      @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .image {
        height: auto;
        max-width: 100%;
        :global(.darkMode) & {
          filter: invert(1);
        }
      }
    }
    .content {
      position: relative;
      z-index: 2;
    }

    &.reverse {
      @include media-breakpoint-down(lg) {
        justify-content: flex-end;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
      }

      .imageWrapper {
        .image {
          @include media-breakpoint-down(lg) {
            position: relative;
            right: -toRem(76) !important;
          }
          @include media-breakpoint-up(lg) {
            left: unset !important;
          }
        }
      }

      .content {
        text-align: right;
      }
    }
  }
}
