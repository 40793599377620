@import "/src/styles/var";
@import "/src/styles/mixins";


.isModal {
  margin: toRem(16) 0 toRem(32);
}
.longFormImage {
  position: relative;

  .imageWrapper {
    > img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    &.boxedImage {
      margin: 0 toRem(32);
      padding: toRem(8);
      border: toRem(1) solid $mirage950;

      .isModal & {
        margin: 0;
      }
    }

    &.fullWidthImage {
      width: 100%;
    }

    &.fourFifthsImage {
      width: 85%;
      margin-left: auto;
    }
  }
}

.longFormImageDescription {
  display: flex;
  max-width: 66%;
  margin: toRem(8) toRem(32) toRem(32);

  .isModal & {
    margin: 0;
    margin-top: toRem(8);
  }

  @include media-breakpoint-down(lg) {
    max-width: 100%;
    margin: toRem(8);
  }

  :global(.MuiTypography-root) {
    color: $mirage950;
    p {
      color: $mirage950;
    }
  }

  &:before {
    content: "";
    height: toRem(1);
    width: toRem(56);
    background-color: $mirage950;
    margin-top: toRem(10);
    margin-right: toRem(8);
  }
}
