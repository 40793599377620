@import '/src/styles/var';
@import '/src/styles/mixins';

.overflowWrap {
  overflow-x: hidden;
  position: relative;
}

.SwiperSlider {
  overflow: visible;
  max-width: 100%;
  z-index: 10;
  margin: 0;
  padding: toRem(64) 0;

  @include media-breakpoint-up(lg) {
    padding: toRem(80) 0;
  }

  .swiperItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: 0.3s all;
    overflow: hidden;
    position: relative;
    opacity: 0.4;
    transition: opacity .25s linear;

    @include media-breakpoint-up(lg) {
      min-height: toRem(296);
      height: auto;
    }

    &:is(:global(.swiper-slide-active)) {
      opacity: 1;
      pointer-events: initial;
    }

    .content {
      width: 100%;

      @include media-breakpoint-up(lg) {
        height: 100%;
      }

      &.iframeWrapper {
        iframe {
          width: 100% !important;
          height: auto;
          overflow-y: auto;
          aspect-ratio: 16/9;
        }

        .panel {
          position: relative;
          @include media-breakpoint-down(lg) {
            &:after {
              content: '';
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              width: 100%;
              height: calc(100% - 75px);
              clip-path: polygon(
                0% 0%,
                0% 100%,
                35% 100%,
                35% 15%,
                65% 15%,
                65% 100%,
                35% 100%,
                35% 100%,
                100% 100%,
                100% 0%
              );
              // clip-path: polygon(
              //   0% 45%,
              //   0% 100%,
              //   35% 100%,
              //   35% 45%,
              //   65% 45%,
              //   65% 100%,
              //   35% 100%,
              //   35% 100%,
              //   100% 100%,
              //   100% 45%
              // );
            }
          }
        }
      }
      
      &.imageWrapper {
        .image {
          width: 100% !important;
          height: auto;
          display: block;
        }
      }
    }
  }

  iframe {
    aspect-ratio: 16/9;
  }

  :global(.swiper-pagination) {
    position: relative;
    margin-top: toRem(32);

    :global(.swiper-pagination-bullet) {
      width: toRem(8);
      height: toRem(8);
      background-color: $grey40;
      opacity: 1;

      &:global(.swiper-pagination-bullet-active) {
        background-color: $green900;
      }
    }
  }

  &:global(.layout-2) {
    .swiperItem {
      opacity: 1;
      img {
        border-radius: toRem(8);
      }
      
      &:nth-child(even) {
        margin-top: 5vh;
      }
    }
  }
}