@import '/src/styles/var';
@import '/src/styles/mixins';

.entryPointContainer {

  &:global(.MuiContainer-root) {
    overflow: hidden;
    min-height: 80vh;
    position: relative;
    z-index: 1;

    :global(.darkMode) & {
      filter: invert(1);
    }

    @include media-breakpoint-down(lg) {
      min-height: 80vw;
      padding: 0 !important;
    }

    .btnMinus,
    .btnPlus {
      display: none;

      @include media-breakpoint-down(lg) {
        animation: fadeIn .5s ease-in-out;
        z-index: 10;
        font-size: toRem(50);
        font-weight: $fontWeightLight;
        position: absolute;
        bottom: toRem(24);
        right: toRem(36);
        width: toRem(24);
        height: toRem(24);
        line-height: toRem(24);
      }
    }

    .btnPlus {
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }



    .entryPointRow {
      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }

      .contentMain {

        min-height: 80vh;
        height: toRem(248);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        color: $white;

        @include media-breakpoint-down(lg) {
          min-height: 100vw;
          overflow: hidden;
          position: relative;
          padding-left: 0;
        }

        &:first-child {

          .contentImage,
          .contentImageGradient,
          .tendinaCont {
            left: 0;
            top: 0;
          }

          .tendinaCont {
            padding-left: 5.5rem;
            padding-right: 0;
            // top: -100%;


            // &:global(.hover) {
            //   top: 0;
            // }



            @include media-breakpoint-down(lg) {
              padding: 0 toRem(22);
            }
          }
        }

        .contentImage,
        .contentImageGradient,
        .tendinaCont {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vw;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          z-index: -1;

          @include media-breakpoint-up(lg) {
            position: absolute;
            top: -1px;
            left: inherit;
            right: 0;
            height: 80vh;
            width: 50%;
          }

          img {
            :global(.darkMode) & {
              filter: invert(0);
            }
          }
        }

        .contentImageGradient {
          height: 60%;
          top: inherit !important;
          bottom: 0;
          background: linear-gradient(180deg, rgba(0, 26, 112, 0) 0%, $blue900 100%);
        }

        .content {
          .titleCont {
            margin-bottom: toRem(60);
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(lg) {
              margin-bottom: toRem(50);
              position: relative;
              width: 100%;
              // height: toRem(248);
            }


            // .btnPlus {
            //   right: toRem(38);
            // }

            .title:global(.MuiTypography-root) {

              display: flex;
              justify-content: center;
              align-items: center;
              color: $white;
              text-align: center;
              max-width: 60%;

              @include media-breakpoint-down(lg) {
                height: auto;
              }
            }
          }

          .entryText {
            position: relative;
            bottom: -20%;
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: bottom .4s ease-in-out, opacity .4s ease-in-out, max-height .6s ease-in-out;
            opacity: 0;


            >button {
              @include media-breakpoint-down(lg) {
                margin-bottom: toRem(32);
              }
            }
          }
        }



        &:global(.hover) {
          bottom: 0;

          @include media-breakpoint-down(lg) {
            .btnMinus {
              display: block;
            }

            .btnPlus {
              display: none !important;
            }
          }

          .entryText {
            opacity: 1;
            // transition:bottom .4s ease-in-out, max-height .6s ease-in-out;
            bottom: 0;
            max-height: 100%;
          }

          // top: 0;
          .tendinaCont {

            opacity: 1;
            z-index: 1;

            .content:global(.MuiGrid-root) {
              bottom: 0;
            }

          }
        }

        .tendinaCont {
          z-index: -1;
          background: linear-gradient(180deg, rgba(0, 26, 112, 0.2) 0%, $blue900 90.4%);
          // transition: bottom .35s ease-in, top .35s ease-in;
          transition: opacity .4s ease-in-out;
          padding-right: 5.5rem;
          padding-left: 0;
          top: inherit;
          opacity: 0;

          @include media-breakpoint-down(lg) {
            padding: 0 toRem(22);
            // transition: bottom .25s ease-in, top .25s ease-in;
            // &:global(.hover) {
            //   top: 50%;
            // }
          }

          // .content:global(.MuiGrid-root) {
          //   position: relative;
          //   transition: bottom .5s ease-in;
          //   bottom: -80vh;
          //   height: 100%;
          //   display: flex;
          //   justify-content: flex-end;
          //   align-items: center;
          //   flex-direction: column;
          //   // padding-bottom: toRem(64);
          //   text-align: center;

          //   .titleCont {
          //     display: flex;
          //     justify-content: center;
          //     align-items: center;
          //     margin-bottom: toRem(24);

          //     .title {
          //       color: $white;
          //       text-align: center;

          //       max-width: 60%;

          //       @include media-breakpoint-down(lg) {}
          //     }
          //   }


          //   .desc {
          //     margin-bottom: toRem(40);
          //     text-align: center;
          //   }
          // }
        }


        .content:global(.MuiGrid-root) {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          padding-bottom: toRem(64);
          text-align: center;
          z-index: 2;

          @include media-breakpoint-down(lg) {
            padding-left: toRem(22);
            padding-right: toRem(22);
            padding-bottom: toRem(26);
          }

          .titleCont {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: toRem(24);

            .title {
              color: $white;
              text-align: center;

              max-width: 60%;

              @include media-breakpoint-down(lg) {}
            }
          }


          .desc {
            margin-bottom: toRem(40);
            text-align: center;
          }
        }
      }
    }
  }
}
