@import "/src/styles/var";
@import "/src/styles/mixins";

.scrollingContentsWrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -1px;
  margin-bottom: -1px;
}

.scrollingContents {
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    // padding-left: toRem(32);
    // padding-right: toRem(32);
  }

  &.scrollingContentsL {
    z-index: 1;

    .scrollingContents__left {
      background-color: $mirage950;
      color: white;
      padding: toRem(48) toRem(16) toRem(32) toRem(32);
      position: relative;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      z-index: 9;
      min-height: calc(100vh - toRem(80));
      height: fit-content;

      @include media-breakpoint-down(lg) {
        height: 100%;
        min-height: 100%;
      }

      @include media-breakpoint-up(lg) {
        left: toRem(56);
      }

      > div {
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-height: inherit;

        > .scrollingContents__left__top {
          display: flex;
          flex: 1;
          flex-direction: column;

          > :global(.MuiTypography-overline) {
            text-decoration: none;
          }
        }

        > .scrollingContents__left__bottom {
          svg {
            transform: rotate(90deg);
          }
          @include media-breakpoint-down(lg) {
            margin-top: toRem(120);
          }
        }
      }

      :global(.MuiTypography-root) {
        color: $athensGray50;
      }

      &__title {
        display: flex;
        line-height: 100% !important;
        margin-top: toRem(16);
      }
    }
  }

  &.scrollingContentsR {
    height: 100%;
    position: absolute;
    left: calc(33.2% + toRem(32));
    top: 0;
    z-index: 1;
    border: toRem(1) solid $mirage950;
    border-right: 0;
    border-left: 0;

    @include media-breakpoint-down(lg) {
      position: relative;
      left: 0;
    }

    .scrollingContents__right {
      // min-height: 200vh;
      display: flex;
      flex-direction: column;
      padding-top: toRem(80);
      padding-bottom: toRem(80);

      @include media-breakpoint-down(lg) {
        padding-top: toRem(0);
        padding-bottom: toRem(32);
        margin-right: toRem(8);
      }

      &__description {
        margin-left: 8.3vw;
        margin-top: toRem(24);

        @include media-breakpoint-down(lg) {
          margin-left: toRem(28);
          margin-top: toRem(8);
        }
      }

      &__cont {
        margin-top: toRem(120);

        @include media-breakpoint-down(lg) {
          margin-top: toRem(48);
        }

        > div > svg {
          margin-right: toRem(22);

          @include media-breakpoint-down(lg) {
            margin-right: toRem(16);
            width: toRem(12);
          }
        }
      }

      :global(.MuiTypography-root) {
        color: $mirage950;
      }
    }
  }
}
