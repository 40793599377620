@import '/src/styles/var';
@import '/src/styles/mixins';

.approccioWrapper {
  &:global(.MuiContainer-root) {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: toRem(80);
    padding-bottom: toRem(80);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: $blue50;

    @include media-breakpoint-down(lg) {
      background-size: auto 65%;
    }

    .row {
      justify-content: center;
      align-items: center;
      min-height: 80vh;
      z-index: 2;

      .content {
        justify-content: center;

        &:nth-child(odd) {

          @include media-breakpoint-down(lg) {
            margin-bottom: toRem(370);
          }
        }

        &:first-child {
          margin-bottom: 0;
        }


        .item {
          @include media-breakpoint-down(lg) {
            padding-top: 2.2rem;
          }
          .itemIcon {
            position: relative;
            margin-bottom: toRem(8);
            width: toRem(104);
            height: toRem(104);
            @include media-breakpoint-down(lg) {
              width: toRem(80);
              height: toRem(80);
            }
            svg {width: 100%; height: auto;}

            .title {
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
  
              @include media-breakpoint-down(lg) {
                margin-bottom: toRem(16);
              }
            }
          }

          

          .subTitle {
            margin-bottom: toRem(12);
            text-align: center;
          }

          .desc {
            text-align: center;
          }
        }
      }
    }

    .animatedNext {
      width: 100%;
      overflow: hidden;
      position:absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      svg {
        width: 200%;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }
    }
  }
}
