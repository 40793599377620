@import '/src/styles/var';
@import '/src/styles/mixins';

.ctaContainer {

  .ctaTitle {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: toRem(32);
  }
}