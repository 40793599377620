@import '/src/styles/var';
@import '/src/styles/mixins';

.caseHistoryWrapper {

  .copyright{
    position: absolute;
    left: 0;
    text-align: right;
    padding: toRem(16);
    color: $white;
    font-size: toRem(16);
    font-weight: normal;
    line-height: toRem(20);
    z-index: 999;
    opacity: 0;
    animation: fadeIn 1.6s ease-in .2s forwards;
    background: linear-gradient(0deg, rgba(0, 26, 112, 0.5) 0%, transparent 100%);
    width: 100%;

    @include media-breakpoint-up(lg) {
      opacity: 1;
      animation: none;
      bottom: 0;
      padding-right: 3vh;

    }
    @include media-breakpoint-down(lg) {
      opacity: 1;
      animation: none;
      font-size: toRem(12);
      line-height: toRem(16);
      opacity: 1;
      animation: none;
      bottom: 0;
      padding-right: 2vh;

    }
  }
  
  &:global(.MuiContainer-root) {
    overflow: hidden;
    animation: fadeIn .25s ease-in;
    position: relative;
    z-index: 1;
    background-color: transparent;

    @include media-breakpoint-down(lg) {
      padding: 0 !important;
    }

    &:nth-child(even) {
      .content {
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }

        .image {
          @include media-breakpoint-up(lg) {
            left: inherit;
            right: 0;
          }
        }

        .boxCont {
          &.align_left {
            @include media-breakpoint-up(lg) {
              text-align: right;
              align-items: flex-end;
            }
          }

          &.align_right {
            @include media-breakpoint-up(lg) {
              text-align: left;
              align-items: flex-start;
            }
          }
        }

        &.img_left {
          flex-direction: column;

          @include media-breakpoint-up(lg) {
            flex-direction: row;
          }

          .image {
            @include media-breakpoint-up(lg) {
              left: 0;
              right: inherit;
            }
          }

          .boxCont {
            &.align_left {
              @include media-breakpoint-up(lg) {
                text-align: right;
                align-items: flex-end;
              }
            }

            &.align_right {
              @include media-breakpoint-up(lg) {
                text-align: left;
                align-items: flex-start;
              }
            }
          }
        }

      }
    }

    .content {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      &.img_left {
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }

        .image {
          @include media-breakpoint-up(lg) {
            left: inherit;
            right: 0;
          }
        }
      }

      &.img_right {
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }

        .image {
          @include media-breakpoint-up(lg) {
            top: -2px;
            left: 0;
          }
        }
      }

      .boxCont {
        padding: toRem(78) toRem(22);
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: flex-start;

        @include media-breakpoint-up(lg) {
          padding: toRem(22) toRem(112);
          width: 50%;
          min-height: 80vh;
        }

        &.align_center {
          text-align: center;
          align-items: center;
        }

        &.align_left {
          @include media-breakpoint-up(lg) {
            text-align: left;
            align-items: flex-start;
          }
        }

        &.align_right {
          @include media-breakpoint-up(lg) {
            text-align: right;
            align-items: flex-end;
          }
        }

        &.boxImg {
          padding: 0;
        }

        .overline {
          text-transform: uppercase;
          margin-bottom: toRem(16);
        }

        .title {
          margin-bottom: toRem(24);
        }

        .desc {
          margin-bottom: toRem(40);
        }


      }

      .image {
        width: 100vw;
        height: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        // :global(.darkMode) & {
        //   filter: invert(1);
        // }

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: -2px;
          left: 0;
          height: 81vh;
          width: 50vw;
        }
      }
    }

    &.primary {
      background: linear-gradient(180deg, #3A76BB 0%, #001A70 100%);
      color: $white;

      .content {
        .boxCont {
          .title {
            color: $white;
          }
        }
      }
    }

  }
}

.caseHistoryFilter {
  &:global(.MuiContainer-root) {
    position: relative;
    z-index: 1;
    margin: toRem(32) 0;

    @include media-breakpoint-up(lg) {
      margin: toRem(54) 0;
    }

    .filterCont {
      display: flex;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      .filterTitle {
        text-transform: uppercase;
        margin-bottom: toRem(17);
        @include font-gen($baseFont, 16, $black, 24, $fontWeightRegular, normal);

        @include media-breakpoint-up(lg) {
          margin-right: toRem(74);
        }
      }

      .filterChips {
        :global(.MuiChip-root) {
          transition: background-color .25s ease-in;

          &:global(.activeFilter) {
            background-color: $green700;
            color: $white;
          }
        }
      }
    }
  }
}

.loadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: toRem(44) 0;

  @include media-breakpoint-up(lg) {
    margin: toRem(80) 0;
  }
}
