@import '/src/styles/var';
@import '/src/styles/mixins';

.modelloOperativo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  :global(.darkMode) & {
    filter: invert(1);
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  .modelloItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: toRem(26) 0 toRem(32);
    background-color: $blue700;

    @include media-breakpoint-up(lg) {
      height: 80vh;
      padding: toRem(26) 0;
    }

    .modelloContent {
      max-width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      text-align: center;
      color: $white;

      @include media-breakpoint-up(lg) {
        max-width: 60%;
        justify-content: flex-start;
        margin-top: 15vh;
      }

      .title {
        color: $white;
        margin: toRem(28) 0;
      }

      .itemCta {
        margin-top: toRem(30);
        font-size: toRem(18);
        padding: 0 toRem(16);

        &:hover, &:active, &:focus {
          @include media-breakpoint-down(lg) {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }

      >svg {
        width: toRem(104);
        height: toRem(104);

        // :global(.MuiDrawer-root) & {
        //   width: toRem(184);
        //   height: toRem(184);
        // }
      }
    }
  }
}

.paperOperativo {

  :global(.darkMode) & {
    filter: invert(1);
  }

  .box {
    color: $white;
    width: 100%;

    .titleCont {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(lg) {
        padding-right: toRem(32);
      }

      >svg {
        width: toRem(92);
        height: toRem(92);
        margin-right: toRem(8);
        margin-bottom: toRem(8);

        @include media-breakpoint-up(lg) {
          width: toRem(184);
          height: toRem(184);
        }
      }

      .title {
        color: $white;
        margin: toRem(26) 0;
      }
    }
  }

  .closeButton {
    color: $white;
    position: absolute;
    top: toRem(16);
    right: toRem(22);

    >svg {
      width: toRem(40);
      height: toRem(40);
    }

    @include media-breakpoint-up(lg) {
      top: toRem(44);
      right: toRem(88);
    }
  }
}