@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormHero {
  min-height: 100vh;
  padding: 0 toRem(8);
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding: 0 toRem(32);
  }

  .longFormHeroContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-left: 1px solid $athensGray50;
    border-right: 1px solid $athensGray50;
    height: 100vh;
    position: relative;
    z-index: 2;
  }

  .longFormHeroLogoBox {
    padding-left: toRem(38);
    padding-top: toRem(36);

    @include media-breakpoint-down(lg) {
      padding-left: toRem(26);
      padding-top: toRem(25);
      svg {
        height: toRem(24);
        width: toRem(123.333);
      }
    }
  }

  .longFormHeroTitle {
    padding-right: 40%;
    padding-left: toRem(38);
    margin-top: toRem(16);

    @include media-breakpoint-down(lg) {
      font-size: toRem(66);
      font-style: normal;
      font-weight: 800;
      line-height: 80%;
      letter-spacing: -1.32px;
      padding-left: toRem(8);
      margin-top: toRem(34);
    }
  }

  .longFormHeroContent {
    margin-bottom: toRem(32);
  }

  .longFormHeroSubtitle {
    padding-left: toRem(38);

    @include media-breakpoint-down(lg) {
      padding-left: toRem(8);
    }
  }

  .longFormHeroSubtitleSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .longFormHeroYearBox {
    display: flex;
    padding: toRem(4) toRem(24);
    background-color: $athensGray50;
    border-radius: toRem(30);
    height: fit-content;
    margin-right: toRem(31);

    @include media-breakpoint-down(lg) {
      position: absolute;
      right: 8px;
      top: 30px;
      margin: 0;
    }
  }

  .longFormHeroYearLabel {
    text-transform: uppercase;
  }

  .longFormHeroDescription {
    display: flex;
    margin-top: toRem(24);
    padding: toRem(16);
    border: 1px solid $athensGray50;
    border-left-width: toRem(14);
    border-right-width: 0;

    @include media-breakpoint-down(lg) {
      border-left-width: 0;
      border-top-width: toRem(8);
      padding: toRem(8);
      margin-top: toRem(16);
    }
  }

  .longFormHeroDescriptionText {
    padding-right: 50%;

    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }
  }

  .longFormHeroScroll {
    display: flex;
    align-items: flex-end;
    min-width: fit-content;
  }

  .longFormHeroScrollContent {
    display: flex;
    align-items: center;
  }

  .longFormHeroScrollButton {
    display: flex;
    width: toRem(50);
    height: toRem(50);
    border-radius: 50%;
    padding: toRem(18);
    background-color: $athensGray50;
    margin-left: toRem(9);
  }

  .longFormHeroBackButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 1%;
    top: 3%;
    width: toRem(42);
    height: toRem(42);
    border-radius: 50%;
    background-color: $athensGray50;
    z-index: 10;

    @include media-breakpoint-down(lg) {
      top: toRem(24);
      left: toRem(-3);
      width: toRem(28);
      height: toRem(28);

      svg {
        width: toRem(18);
      }
    }
  }

  .imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
}
