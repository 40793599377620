@import '/src/styles/var';
@import '/src/styles/mixins';

.introWrapper {
  &:global(.MuiContainer-root) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: toRem(48);
    padding-bottom: toRem(48);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -50vw 5vh;

    @include media-breakpoint-up(lg) {
      background-size: auto 80%;
      background-position: left center;
      padding-top: toRem(80);
      padding-bottom: toRem(80);
    }

    &.background {
      min-height: calc(80vh - toRem(72));

      @include media-breakpoint-up(lg) {
        min-height: calc(80vh - toRem(92));
      }
    }

    .animatedIcon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 80%;
      z-index: 1;

      &.left{
        text-align: right;
      }
      &.right{
        text-align: left;
      }

      @include media-breakpoint-down(lg) {
        height: 50%;
        left: -30%;
        top: 0%;
        transform: none;
      }

      svg {
        height: 100%;
        max-width: 100%;
      }
    }

    .contentWrapper {
      position: relative;
      z-index: 2;

      .content {
        justify-content: center;
        align-items: center;

        .title {
          color: $blue900;
          margin-bottom: toRem(24);
        }

        .button {
          margin-top: toRem(40);
        }
      }
    }
  }

  &:global(+ .paragraph_obbiettivi:not(.paragraph_obbiettivi_background, .paragraph_obbiettivi_overlay)) {
    margin-top: - toRem(40);
    @include media-breakpoint-up(lg) {
      margin-top: -15vh;
    }
  }
}