@import "/src/styles/var";
@import "/src/styles/mixins";

.longformAdvantagesList {
  margin: 0 toRem(32);
  position: relative;

  @include media-breakpoint-down(lg) {
    margin: 0 toRem(8);
  }

  &.isModal {
    margin-right: 0;

    .borderedWrapper {
      border: 0;
      border-left: toRem(1) solid $mirage950;
    }
  }

  .borderedWrapper {
    border: toRem(1) solid $mirage950;
    padding: toRem(48) toRem(25);

    @include media-breakpoint-down(lg) {
      padding: toRem(8) toRem(16);
    }

    :global(.MuiTypography-root) {
      color: $mirage950 !important;
    }

    .headerBox {
      color: $mirage950;
      padding: toRem(16);
      position: relative;

      .line {
        width: toRem(32);
        height: toRem(1);
        background-color: $mirage950;
        position: absolute;
        left: toRem(-26);
        top: toRem(28);

        @include media-breakpoint-down(lg) {
          top: toRem(20);
          left: toRem(-16);
        }
      }

      :global(.MuiTypography-root) {
        font-weight: bold;
        margin-left: toRem(16);
        display: inline-block;
      }

      @include media-breakpoint-down(lg) {
        padding: toRem(8);
      }
    }
    .listBox {
      padding: toRem(16);
      display: flex;
      text-align: left;
      position: relative;

      @include media-breakpoint-down(lg) {
        padding: toRem(8) toRem(16);
      }

      > svg {
        position: absolute;
        width: toRem(16);
        height: toRem(16);
        left: toRem(-34);
        top: toRem(24);

        @include media-breakpoint-down(lg) {
          left: toRem(-22);
          top: toRem(12);
          width: toRem(12);
          height: toRem(12);
        }
      }
    }
  }
}
