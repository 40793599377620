@import '/src/styles/var';
@import '/src/styles/mixins';

.videoPlayer {
  position: relative;

  .preview {
    width: 100%;
    height: auto;
  }

  .btnPlay {
    display: block;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    :global(.darkMode) & {
      filter: invert(1);
    }

    >svg {
      width: toRem(32);
      height: toRem(32);

      @include media-breakpoint-up(lg) {
        width: toRem(80);
        height: toRem(80);
      }
    }
  }

  .video {
    width: 100%;
    height: auto;
    animation: fadeIn .8s ease-in;

    :global(.darkMode) & {
      filter: invert(1);
    }
  }
}

//  video in modale
// .paperVideo {

//   :global(.darkMode) & {
//     filter: invert(1);
//   }

//   .box {
//     color: $white;
//     width: 100%;
//     height: 100vh;

//     .video {
//       width: 100%;
//       height: auto;
//       max-height: 100%;
//     }
//   }

//   .closeButton {
//     color: $white;
//     position: absolute;
//     top: toRem(16);
//     right: toRem(22);

//     >svg {
//       width: toRem(40);
//       height: toRem(40);
//     }

//     @include media-breakpoint-up(lg) {
//       top: toRem(44);
//       right: toRem(88);
//     }
//   }
// }
