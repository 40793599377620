@import '/src/styles/var';
@import '/src/styles/mixins';

.articleCard {
  transition: box-shadow 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;

  @include media-breakpoint-up(lg) {
    &:hover {
      box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);
    }
  }

  .content {
    padding: toRem(24) toRem(24) 0;
    background-color: $white;
    min-height: toRem(187);

    .title {
      color: $grey100;
      font-weight: bold;
      margin-top: toRem(16);
      margin-bottom: toRem(24);

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .button {
    padding: 0 toRem(24) toRem(24);
    margin-top: auto;
  }
}