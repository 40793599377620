@import "/src/styles/var";
@import "/src/styles/mixins";

.longFormMainContent {
  display: flex;
  padding: 0 toRem(32);
  background-color: $athensGray50;
  justify-content: flex-end;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 0 toRem(8);
  }

  &.dropCap {
    padding: toRem(32) toRem(32) 0 toRem(32);

    @include media-breakpoint-down(lg) {
      padding: toRem(16) toRem(8) 0 toRem(8);
    }

    .longFormMainContentLeft {
      padding-top: toRem(186);
      border: 0;

      @include media-breakpoint-down(lg) {
        width: 100%;
        padding-top: toRem(56);
      }
    }
  }

  .longFormMainContentLeft {
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 30%;
    min-width: 30%;
    overflow: hidden;
    border-top: 1px solid $mirage950;
    padding-top: toRem(16);

    &.lineBottom {
      border-bottom: 1px solid $mirage950;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 100%;
    }

    .longFormMainContentLeftUp {
      text-align: right;
      // LeftDown
      // justify-content: flex-end;
      // display: flex;
      // width: 100%;
      // flex-direction: column;

      @include media-breakpoint-down(lg) {
        text-align: left;
        margin-bottom: toRem(32);
        padding-right: toRem(8);
        padding-left: toRem(8);
      }

      .longFormMainContentLeftUpTitle {
        margin-top: toRem(16);
        margin-bottom: toRem(16);
        padding-right: toRem(32);
      }

      .longFormMainContentLeftUpSubtitle {
        padding-top: toRem(16);
        padding-left: toRem(67);
        border-top: 1px solid $mirage950;
        @include media-breakpoint-down(lg) {
          padding-left: toRem(0);
        }

        > p {
          color: $mirage950;
          padding-right: toRem(32);
        }
      }
    }
  }
  .longFormMainContentLeftDown {
    // border-bottom: 1px solid $mirage950;
    // display: flex;
    // justify-content: flex-end;
    // height: 100%;
    // flex-direction: column;

    @include media-breakpoint-down(lg) {
      margin-top: toRem(32);
      padding-right: toRem(8);
      padding-left: toRem(8);
    }

    .longFormMainContentLeftNumber {
      padding-right: toRem(16);
      padding-bottom: toRem(32);

      .longFormMainContentLeftNumberTitle {
        display: flex;
        flex-direction: row;
        > p {
          font-size: 8.5vw;
          letter-spacing: -2.8px;
          color: $mirage950;

          :global(.smallText) {
            font-size: 4vw;
            line-height: 0.85;
            display: block;
          }

          @include media-breakpoint-down(lg) {
            font-size: toRem(140);

            :global(.smallText) {
              font-size: toRem(56);
            }
          }
        }
      }

      .longFormMainContentLeftNumberDesc {
        padding-right: 30%;
        padding-top: toRem(16);
        border-top: 16px solid $mirage950;
        margin-top: toRem(16);

        :global(.MuiTypography-root) {
          > p {
            font-size: toRem(16);
            text-transform: uppercase;
            color: $mirage950;
          }
        }
      }
    }

    .longFormMainContentLeftImage {
      padding-right: toRem(16);
      padding-left: toRem(16);
      padding-bottom: toRem(32);
      margin-top: toRem(32);
    }
  }

  .longFormMainContentRight {
    padding: toRem(32);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // padding-top: toRem(220);
    border: 1px solid $mirage950;
    width: 70%;

    &.rightImage {
      padding: 0;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: toRem(8);
      // padding-top: toRem(120);
    }

    .longFormMainContentRightText {
      .dropCap {
        margin-top: 120px;

        @include media-breakpoint-down(lg) {
          margin-top: 80px;
        }

        p:first-of-type::first-letter {
          font-family: "mixta-sharp";
          font-size: toRem(400);
          font-style: normal;
          font-weight: 700;
          line-height: 60%;
          letter-spacing: 45px;

          @include media-breakpoint-down(lg) {
            font-size: toRem(200);
          }
        }
      }
    }

    .longFormMainContentRightImage {
      width: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;
      align-items: center;
      display: flex;

      > img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  &.isModal {
    padding: 0;
    margin: 0;
    position: relative;

    :after {
      content: "";
      position: absolute;
      bottom: 0;
      left: toRem(-32);
      width: calc(100% + toRem(64));
      height: 1px;
      background-color: $mirage950;

      @include media-breakpoint-down(lg) {
        left: toRem(-8);
        width: calc(100% + toRem(16));
      }
    }

    .longFormMainContentRight,
    .longFormMainContentLeft {
      border: 0;

      &.hide {
        display: none;
      }
    }

    .longFormMainContentRight {
      width: 100%;
    }

    .longFormMainContentLeft {
      padding-top: 0;
      overflow: visible;
      .longFormMainContentLeftDown {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: toRem(-32);
          width: toRem(16);
          height: 100%;
          background-color: $mirage950;
        }
      }
    }

    .longFormMainContentLeftNumberTitle {
      > p {
        font-size: toRem(96) !important;

        @include media-breakpoint-down(lg) {
          font-size: toRem(80) !important;
        }
      }
    }

    .longFormMainContentLeftNumber {
      .longFormMainContentLeftNumberDesc {
        border-top: 1px solid $mirage950;

        :global(.MuiTypography-root) {
          > p {
            text-transform: inherit;
          }
        }
      }
    }

    .description.dropCap {
      margin: 0;
    }

    .description {
      > svg {
        margin-right: toRem(24);
        float: left;

        @include media-breakpoint-down(lg) {
          width: toRem(40);
          height: toRem(40);
        }
      }
    }
  }
}

:global(.longform-image) {
  + .longFormMainContent {

    .longFormMainContentLeft {
      border-top: none;
    }
  }
}