@import '/src/styles/var';
@import '/src/styles/mixins';

.servicesWrapper {
  min-height: calc(80vh - toRem(72));
  position: relative;
  overflow: hidden;

  background-size: auto 100%;
  background-color: $white;

  padding-top: toRem(176);
  padding-bottom: toRem(60);

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-size: 100% 100%;

    padding-top: toRem(240);
    padding-bottom: toRem(220);
  }

  .servicesBackground {
    :global(.darkMode) & {
      filter: invert(0);
    }
  }

  .serviceRow {
    position: relative;

    > div {
      position: relative;
      z-index: 10;
      &:nth-child(even) {
        @include media-breakpoint-up(lg) {
          top: toRem(90);
        }
      }
    }

    .serviceItem {
      padding: toRem(20);
      display: flex;
      align-items: center;
      gap: toRem(8);
      position: relative;
      z-index: 10;
      transition: all .25s ease-in;

      &:hover {
        background-color: $white;
        box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);
      }

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: flex-start;
        padding: toRem(24);
        gap: toRem(16);
        min-height: toRem(400);
      }
      .icon {
        svg {
          width: toRem(72);
          height: toRem(72);
          @include media-breakpoint-up(lg) {
            width: toRem(88);
            height: toRem(88);
          }
        }
      }

      .title {
        font-weight: 700;
        color: $grey100;
      }
      .description {
        color: $grey100;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .button {
        margin-left: auto;
        @include media-breakpoint-up(lg) {
          margin-left: - toRem(12);
        }

      }
    }
  }

  .imageElement {
    position: relative;
    z-index: 10;
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: - toRem(320);
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .track {
    position: absolute;
    width: 40%;
    top: toRem(60);
    left: toRem(40);
    z-index: 1;

    @include media-breakpoint-up(lg) {
      top: 60px;
      left: -50px;
      width: 100%;
    }

    svg {
      @include media-breakpoint-up(lg) {
        width: 80vw;
        height: toRem(500);
      }
    }


  }


  .ondeTop,
  .ondeBottom {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  .ondeBottom {
    svg {
      top: unset;
      bottom: 0;
    }
  }
}
