@use "sass:math";
$pageWidth: 1400;
$pageWidthMobile: 360;

@import '/src/styles/var';
@import '/src/styles/mixins';

.numeriBackground {
  background: url(/assets/images/background_chisiamo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 120px;

  @include media-breakpoint-down(md) {
      background-size: 550%;
  }

  :global(.darkMode) & {
    filter: invert(1);
  }
}

.numeriWrapper {
  height: 2850px;
  position: relative;

  >div {
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    height: auto;

    > div {
      position: static;

      .title {
        font-size: 48px;
        padding-bottom: 30px;
      }

      .text {
        font-size: 22px;
      }
    }
  }
}   
.numeriItem0 {
  text-align: right;
  top: 0;
  left: math.percentage(math.div(620,$pageWidth));
  .text {
    color: $grey100;
  }
  @include media-breakpoint-down(md) {
    text-align: left;
    margin-bottom: 80px;
    margin-left: math.percentage(math.div(20,$pageWidthMobile));
  }

  &Animation {
    top: 140px;
    left: math.percentage(math.div(620,$pageWidth));
    .animationWrapper {
      width: 600px; 
      height: auto;
      margin-bottom: 8px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
      padding-left: 22px;
      padding-right: 22px;

      .animationWrapper {
        width: auto; 
        height: auto;
      }
    }
  }
}

.numeriItem1 {
    text-align: right;
    top: 290px;
    left: math.percentage(math.div(255,$pageWidth));

    .text {
      color: $grey100;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 80px;
        margin-right: math.percentage(math.div(105,$pageWidthMobile));
    }
}

.numeriItem2 {
    top: 350px;
    left: math.percentage(math.div(1020,$pageWidth));

    > div {
        width: 184px; 
        height: 322px;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 100px;
        margin-left: math.percentage(math.div(130,$pageWidthMobile));

        > div {
            width: 100px; 
            height: 250px;
        }
    }
}

.numeriItem3 {
    text-align: right;
    top: 540px;
    left: math.percentage(math.div(620,$pageWidth));    
    .text {
      color: $grey100;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
        margin-right: math.percentage(math.div(55,$pageWidthMobile));

        .title, .text {
            color: $white;
        }
    }
}

.numeriItem4 {
    top: 1100px;
    left: math.percentage(math.div(824,$pageWidth));

    .title, .text {
        color: $white;
    }

    @include media-breakpoint-down(md) {
        text-align: right;
        margin-bottom: 30px;
        margin-right: math.percentage(math.div(105,$pageWidthMobile));
    }
}

.numeriItem5 {
    text-align: right;
    top: 1180px;
    left: math.percentage(math.div(194,$pageWidth));

    > div {
        width: 184px; 
        height: 296px;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 120px;
        margin-left: math.percentage(math.div(130,$pageWidthMobile));

        > div {
            width: 100px; 
            height: 250px;
        }
    }
}

.numeriItem6 {
    text-align: right;
    top: 1340px;
    left: math.percentage(math.div(620,$pageWidth));

    .title, .text {
        color: $white;
    }

    @include media-breakpoint-down(md) {
        text-align: left;
        margin-bottom: 80px;
        margin-left: math.percentage(math.div(20,$pageWidthMobile));
    }
}

.numeriItem7 {
    text-align: right;
    top: 1840px;
    left: math.percentage(math.div(160,$pageWidth));

    .title, .text {
        color: $white;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
        margin-right: math.percentage(math.div(55,$pageWidthMobile));
    }
}

.numeriItem11 {
    text-align: right;
    top: 1600px;
    left: math.percentage(math.div(360,$pageWidth));

    .title, .text {
        color: $white;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 70px;
        margin-right: math.percentage(math.div(80,$pageWidthMobile));
    }
}

.numeriItem8 {
    top: 1840px;
    left: math.percentage(math.div(1020,$pageWidth));

    > div {
        width: 392px; 
        height: 310px;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 110px;
        margin-left: math.percentage(math.div(75,$pageWidthMobile));

        > div {
            width: 210px; 
            height: 165px;
        }
    }
}

.numeriItem9 {
    text-align: right;
    top: 2100px;
    left: math.percentage(math.div(360,$pageWidth));

    .title, .text {
        color: $white;
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 95px;
        margin-right: math.percentage(math.div(95,$pageWidthMobile));
    }
}

.numeriItem10 {
    text-align: right;
    top: 2500px;
    left: math.percentage(math.div(560,$pageWidth));

    @include media-breakpoint-up(md) {
        .title, .text {
            color: $white;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        margin-right: math.percentage(math.div(25,$pageWidthMobile));
    }
}

