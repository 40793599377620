@import '/src/styles/var';
@import '/src/styles/mixins';

.management {
  @include media-breakpoint-up(lg) {
    padding-bottom: toRem(60);
  }
    .swiper {
      padding: toRem(32) 0 toRem(48);
      :global(.swiper-pagination) {
        bottom: 0;
        :global(.swiper-pagination-bullet) {
          background-color: $grey40;
        }
        :global(.swiper-pagination-bullet-active) {
          background-color: $green900;
        }
      }
    }
  
  .managementItemWrapper {
    &:nth-child(odd) {
      margin-top: toRem(60);
    }
  }
  .managementItem {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: toRem(8);
    max-width: toRem(288);
    margin-left: auto;
    margin-right: auto;
    box-shadow: none;
    transition: all 0.25s linear;
    padding: toRem(5) toRem(12);
    height: toRem(385);

    .image {
      width: toRem(264);
      height: toRem(264);
      border-radius: 50%;
      object-fit: cover;
      flex: 0 0 auto;
      transition: all 0.25s linear;
    }

    .itemHeading {
      width: 100%;
      transition: all 0.25s linear;
      .title {
        color: $grey100;
      }
    }

    .icon {
      transition: all 0.25s linear;
      position: absolute;
      top: toRem(170);
      right: 0;
      cursor: pointer;
      z-index: 2;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        width: toRem(26);
        height: toRem(20);
        border-radius: 50%;
        z-index: -1;
      }
    }

    &.active {
      // padding: toRem(15) toRem(32) toRem(15) toRem(18);
      background-color: $white;
      box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);

      .image {
        width: toRem(64);
        height: toRem(64);
      }

      .itemHeading {
        width: 75%;
        transform: translate(74px, -70px);
      }

      .content {
        margin-top: toRem(10);
        padding-right: toRem(17);
        overflow: auto;
        transform: translateY(-60px);
        max-height: 280px;

      }

      .icon {
        transform: translateX(16px);
      }
    }
  }

}
